<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">
        <p>Danh sách hợp đồng</p>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-4">
        <div class="col-md-3">
          <el-date-picker
              v-model="query.created_at"
              type="daterange"
              format="dd-MM-yyyy"
              value-format="yyyy-MM-dd"
              start-placeholder="Ngày bắt đầu"
              end-placeholder="Ngày kết thúc">
          </el-date-picker>
        </div>
        <div class="col-md-3">
          <el-input placeholder="Tên, SĐT khách hàng" v-model="query.keyword"></el-input>
        </div>
        <div class="col-md-3">
          <el-select
              @input="handleCenter"
              filterable
              clearable
              multiple
              v-model="query.center_ids"
              placeholder="Trung tâm ">
            <el-option
                v-for="item in centers"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              <span style="float: left">{{ item.name }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="col-md-3">
          <el-select
              filterable
              clearable
              multiple
              v-model="query.branch_ids"
              placeholder="Chi nhánh">
            <el-option
                v-for="item in branches"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              <span style="float: left">{{ item.name }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="col-md-3 mt-4">
          <el-select
              filterable
              clearable
              v-model="query.status"
              placeholder="Trạng thái">
            <el-option
                v-for="item in status"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              <span style="float: left">{{ item.name }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="col-md-3 mt-4">
          <el-select
              filterable
              clearable
              v-model="query.tuition_payment_id"
              placeholder="Hình thức đóng học phí">
            <el-option
                v-for="item in tuitionPaymentSelect"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              <span style="float: left">{{ item.name }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="col-md-3 mt-4">
          <el-select
              filterable
              clearable
              v-model="query.total_paid"
              placeholder="Số tiền đã đóng">
            <el-option
                v-for="item in prices"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              <span style="float: left">{{ item.name }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="col-md-3 mt-4">
          <button class="btn btn-primary" @click="search">
            <i class="el-icon-loading" v-if="is_loading_search"></i>
            Tìm kiếm
          </button>
        </div>
      </div>
      <el-table
          :data="tableData"
          stripe
          style="width: 100%">
        <el-table-column label="STT" type="index" width="50"></el-table-column>
        <el-table-column
            prop="name"
            label="Họ và tên"
            width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.customer ? scope.row.customer.name : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Số điện thoại"
            width="200">
          <template slot-scope="scope">
            {{ scope.row.customer ? scope.row.customer.phone : '' }}
          </template>
        </el-table-column>
        <el-table-column
            label="Mã hợp đồng"
            width="200">
          <template slot-scope="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column
            label="Số tiền phải đóng"
            width="200">
          <template slot-scope="scope">
            {{ scope.row.total_after_voucher | formatVND }}
          </template>
        </el-table-column>
        <el-table-column
            label="Đã đóng"
            width="130">
          <template slot-scope="scope">
            {{ scope.row.total_paid | formatVND }}
          </template>
        </el-table-column>
        <el-table-column
            label="Công nợ"
            width="130">
          <template slot-scope="scope">
            {{ scope.row.total_debt | formatVND }}
          </template>
        </el-table-column>
        <el-table-column
            label="Trạng thái"
            width="130">
            <template slot-scope="scope">
            <span v-if="scope.row.is_cancel == 1" :class="status_define_css[2]">{{ status_define[2] }}</span>
            <span v-else :class="status_define_css[scope.row.status]">{{ status_define[scope.row.status] }}</span>
            </template>

        </el-table-column>
        <el-table-column
            label="Loại sản phẩm"
            width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.is_product_edutalk" class="badge badge-primary">Edutalk</span>
            <span v-else class="badge badge-success">Đối tác</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Hình thức thanh toán"
            width="180">
          <template slot-scope="scope">
            <span :class="tuitionPaymentCss[scope.row.tuition_payment_id]">{{
                tuitionPayment[scope.row.tuition_payment_id]
              }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Thời gian tạo"
            width="200">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            {{ scope.row.created_at | formatDateTime }}
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="Hành động"
            width="200">
            <template slot-scope="scope">
              <div v-if="scope.row.is_cancel != 1">
                <div v-if="scope.row.is_product_edutalk">
                  <div title="Sửa" v-if="!scope.row.total_paid"  @click="editContractEdutalk(scope.row)" class="btn btn-outline-primary btn-icon mr-2 btn-outline-info edit-contracts"><i class="el-icon-edit"></i></div>
                  <router-link :to="{ name: 'contract-show-edutalk', params: { contract_id: scope.row.id }, query : {customer_id : scope.row?.customer?.id} }" title="Xem"
                               class="btn btn-outline-success btn-icon mr-2 btn-outline-info"><i class="el-icon-view"></i>
                  </router-link>
                </div>
                <div v-else>
                  <div title="Sửa" link v-if="!scope.row.total_paid"  @click="editContractPartner(scope.row)" class="btn btn-outline-primary btn-icon mr-2 btn-outline-info edit-contracts"><i class="el-icon-edit"></i></div>
                  <router-link :to="{ name: 'contract-show-partner', params: { contract_id: scope.row.id }, query : {customer_id : scope.row?.customer?.id} }" title="Xem"
                               class="btn btn-outline-success btn-icon mr-2 btn-outline-info"><i class="el-icon-view"></i>
                  </router-link>
                </div>
              </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- dialog xác thực hợp đồng lần 2 -->
    <el-dialog class="confirm-contract" title="Sửa hợp đồng" :visible.sync="confirmContract" width="30%" style="line-height: 18px; z-index: 2001; color: rgb(0, 0, 0); margin-top: 25vh;">
      <hr>
      <p style="word-break: break-word;">Hợp đồng hiện tại đã được xác thực. Khách hàng cần phải xác thực lại một lần nữa nếu hợp đồng bị thay
        đổi.</p>
      <span class="pt-6">Bạn có chắc chắn muốn sửa chứ?</span>
      <span slot="footer" class="dialog-footer">
        <el-button style="color: #000; background-color: #E6E6E6;" @click="confirmContract = false">Huỷ bỏ</el-button>
        <el-button type="primary" style="color: #fff; background-color: #500DA7;" @click="confirmAccept">Xác
          nhận</el-button>
      </span>
    </el-dialog>

      <div class="edu-paginate mx-auto  mt-3 d-flex justify-content-center">
        <paginate
            v-model="page"
            :page-count="last_page"
            :page-range="3"
            :margin-pages="1"
            :click-handler="clickCallback"
            :prev-text="'Trước'"
            :next-text="'Sau'"
            :container-class="'pagination b-pagination'"
            :pageLinkClass="'page-link'"
            :next-link-class="'next-link-item'"
            :prev-link-class="'prev-link-item'"
            :prev-class="'page-link'"
            :next-class="'page-link'"
            :page-class="'page-item'"
        >
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import {CONTRACT_ONLINE_LIST_BY_TVV} from "@/core/services/store/contract/contractOnline.module";
import {
  price_select,
  status_define,
  status_define_css,
  status_select, tuitionPayment, tuitionPaymentCss, tuitionPaymentSelect
} from "@/core/config/contract/contractOnlineOption";
import {GET_CENTERS} from "@/core/services/store/center/center.module";
import {GET_BRANCHES, GET_BRANCHES_ALL} from "@/core/services/store/center/branch.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "ContractIndex",
  data() {
    return {
      page: 1,
      last_page: 1,
      is_loading_search: false,
      tableData: [],
      centers: [],
      branches: [],
      status: status_select,
      prices: price_select,
      status_define: status_define,
      status_define_css: status_define_css,
      tuitionPayment: tuitionPayment,
      tuitionPaymentCss: tuitionPaymentCss,
      tuitionPaymentSelect: tuitionPaymentSelect,
      confirmContract: false,
      idContract: '',
      idCustomer: '',
      nameContract:'',

      query: {
        keyword: this.$route.query.phone ? this.$route.query.phone : '',
        center_ids: [],
        branch_ids: [],
        status: '',
        total_paid: '',
        tuition_payment_id: '',
        created_at: [],
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Tư vấn"},
      {title: "Danh sách hợp đồng tư vấn", icon: 'far fa-user'}
    ]);
    this.getList();
    this.getCenter();
  },
  methods: {

    editContractEdutalk(row) {
        console.log("aaaaaaaaaaaaaaaaaa",row);
        if(row.status === 1 && row.is_product_edutalk == 1) {
          this.confirmContract = true;
        }else {
          this.$router.push({ name: 'contract-edit-edutalk', params: { contract_id: row.id }, query : {customer_id : row?.customer?.id} })
        }
        this.idContract = row.id;
        this.idCustomer = row?.customer?.id;
        this.nameContract = 'contract-edit-edutalk';
    },

    editContractPartner(row) {
        if(row.status === 1 && row.is_product_edutalk == 1) {
          this.confirmContract = true;
        }else {
          this.$router.push({ name: 'contract-edit-partner', params: { contract_id: row.id }, query : {customer_id : row?.customer?.id} })
        }
        this.idContract = row.id;
        this.nameContract = 'contract-edit-partner';
       },

      //  nhấn xác nhận hợp đồng đã được xác thực thì chuyển sang hợp đồng tương ứng
        confirmAccept() {
          this.$router.push({ name: this.nameContract, params: { contract_id: this.idContract }, query : {customer_id : this.idCustomer} })
        },

    search() {
      this.page = 1;
      this.pushParamsUrl();
      this.getList();
    },
    getCenter() {
      this.$store.dispatch(GET_CENTERS, {
        limit: 1000000
      }).then((data) => {
        this.centers = data.data.data;
      });
    },
    getBranch() {
      this.$store.dispatch(GET_BRANCHES_ALL, {
        limit: 1000000,
        center_ids: this.query.center_ids
      }).then((data) => {
        this.branches = data.data.data;
      });
    },
    clickCallback(obj) {
      this.page = obj;
      this.pushParamsUrl();
      this.getList()
    },

    pushParamsUrl() {
      let query = {
        page: this.page,
        ...this.query
      }
      this.$router.push({
        name: 'contract-index',
        query: query
      })
    },
    handleCenter() {
      this.query.branch_id = '';
      this.branches = [];
      this.getBranch();
    },
    getList() {
      this.is_loading_search = true;
      this.$store.dispatch(CONTRACT_ONLINE_LIST_BY_TVV, {
        page: this.page,
        ...this.query
      }).then((data) => {
        this.tableData = data.data;
        this.last_page = data.pagination.last_page;
      }).finally(() => {
        this.is_loading_search = false;
      });
    }
  }
}
</script>

<style scoped>

.edit-contracts {
  cursor: pointer;
}
.el-select {
  width: 100%;
}

.el-date-editor--daterange {
  width: 100%;
}

.el-range-separator {
  width: 40% !important;
}
</style>

<style >
@media only screen and (max-width: 425px) {
  .confirm-contract .el-dialog{
    width: 80% !important;
  }
}

@media only screen and (min-width: 425.5px) and (max-width: 768px) {
  .confirm-contract .el-dialog{
    width: 50% !important;
  }
}

.confirm-contract .el-dialog__header {
  padding: 20px 20px 0px !important;
}
.badge-red {
  background-color: #BC0001;
}
</style>
